/**
* Implement Gatsby's Browser APIs in this file.
*
* See: https://www.gatsbyjs.com/docs/browser-apis/
*/
// You can delete this file if you're not using it
import "././node_modules/bootstrap/dist/css/bootstrap.min.css" // This to be added.
import "./src/style/style.scss";
import "./src/style/style.css";
import "./src/style/responsive.css";
import ReactDOM from "react-dom/client";


export const replaceHydrateFunction = () => {
    return (element, container) => {
        const root = ReactDOM.createRoot(container)
        root.render(element)
    }
}